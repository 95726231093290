import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { RegisterFormData } from 'data/types/auth';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import useFetchCountries from 'hooks/useFetchCountries';
import { showToast } from 'data/utils/toast';
import { billingCurrencyOptions } from 'data/constants/payment.constants';
import { registerUser } from 'services/api/api';
import { getEncryptedValue } from 'data/utils/common';
import { registerFormValidation } from '../Registration.schema';
import { PrimaryButton, RFSelectField } from '../../../Common';

interface IRegistrationFormProps {
    setEmail: Dispatch<SetStateAction<string>>;
    setStep: Dispatch<SetStateAction<number>>;
}

const RegistrationForm = ({ setStep, setEmail }: IRegistrationFormProps) => {
    const [loading, setLoading] = useState(false);
    const { countries } = useFetchCountries();

    const { control, handleSubmit } = useForm<RegisterFormData>({
        resolver: zodResolver(registerFormValidation),
        defaultValues: {
            isReceivedOffers: false,
        },
    });

    const onSubmit = handleSubmit(async (formData) => {
        const REACT_APP_X_API_KEY = 'asdsdgfgsdf'; // TODO remove this keys after env setup

        // if (
        //     !process.env.REACT_APP_X_API_KEY ||
        //     !process.env.REACT_APP_ENCRYPTED_KEY
        // ) {
        //     return showToast('Internal server', 'error');
        // }

        const xApiKey = getEncryptedValue(REACT_APP_X_API_KEY) as string;
        setLoading(true);
        try {
            const response = await registerUser(formData, xApiKey);
            showToast(response.message, 'success');
            setStep(2);
            setEmail(formData.email);
        } catch (err: any) {
            showToast(err?.errors?.[0]?.msg ?? err.message, 'error');
        } finally {
            setLoading(false);
        }
    });

    return (
        <div className="my-6">
            <div className="space-y-4">
                <div className="font-merri-bold text-[32px] text-[#2E672F] leading-10">
                    Register new account
                </div>
                <div className="leading-6 text-[#131119]">
                    Creating a new Mozarto account is simple and hassle-free.
                    You can register in just three easy steps: provide general
                    information, verify your email address, and set up your
                    password.
                </div>
            </div>
            <form onSubmit={onSubmit} className="mt-12 w-full">
                <div className="flex items-center gap-4">
                    <RFTextField
                        control={control}
                        name="firstName"
                        fullWidth
                        label="First name"
                        type="text"
                        asterisk
                    />
                    <RFTextField
                        control={control}
                        name="lastName"
                        fullWidth
                        label="Last name"
                        type="text"
                        asterisk
                    />
                </div>
                <RFTextField
                    control={control}
                    name="email"
                    fullWidth
                    label="Email address"
                    type="email"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="company"
                    fullWidth
                    label="Company name"
                    type="text"
                    asterisk
                />
                <RFSelectField
                    name="billingCurrency"
                    control={control}
                    fullWidth
                    asterisk
                    label="Billing currency"
                    style={{ height: '50px !important' }}
                    options={billingCurrencyOptions}
                    bordered
                />
                <p className="leading-4 text-xs text-[#131119]">
                    Note: No billing happens until plan selection
                </p>
                <RFSelectField
                    name="country"
                    control={control}
                    fullWidth
                    asterisk
                    label="Country"
                    options={[
                        {
                            _id: '',
                            name: 'Select Country',
                        },
                        ...countries.map((data: any, index) => ({
                            id: index,
                            name: data?.name?.common,
                        })),
                    ]}
                    bordered
                />
                <RFTextField
                    control={control}
                    name="isReceivedOffers"
                    fullWidth
                    label={
                        <div className="text-[#383838] text-sm leading-[18px] w-96">
                            Receive promotional emails and offers?
                        </div>
                    }
                    type="checkbox"
                    asterisk
                    isCheckbox
                />
                <RFTextField
                    control={control}
                    name="policy"
                    fullWidth
                    label={
                        <div className="text-[#383838] text-sm leading-[18px] w-96">
                            Agree{' '}
                            <Link
                                to="/registration"
                                className="text-[#8FB131] underline"
                            >
                                Privacy Policy
                            </Link>{' '}
                            and our{' '}
                            <Link
                                to="/registration"
                                className="text-[#8FB131] underline"
                            >
                                Terms and Conditions
                            </Link>
                        </div>
                    }
                    type="checkbox"
                    asterisk
                    isCheckbox
                />
                <div className="mt-14">
                    <PrimaryButton
                        type="submit"
                        isDrawerButton
                        className="w-full"
                        color="#2E672F"
                        variant="filled"
                        name={loading ? '' : 'Continue to verification'}
                        loading={loading}
                        disabled={loading}
                    />
                </div>
                <div className="mt-4 text-center text-base leading-5">
                    Already have an account?{' '}
                    <Link to="/" className="text-[#8FB131] font-poppins-bold">
                        Log In here
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default RegistrationForm;
